/* eslint-disable eqeqeq */
import { firebaseDB } from "@/services/Firebase";
import { ReservationStatus, SeatStatus, SeatingType } from "@/utils/enum";
import {
  cloneCopy,
  getStorage,
  orderBy,
  saveStorage,
  sumByCol,
  toDecimal,
} from "@/utils/helper";
import NotEnoughTicketsLeftError from "@/utils/notEnoughTicketsLeftError";
import SeatAlreadyOccupiedError from "@/utils/seatAlreadyOccupiedError";

import Dinero from "dinero.js";

import {
  collection,
  query,
  getDocs,
  doc,
  onSnapshot,
  setDoc,
  runTransaction,
  Timestamp,
  where,
  limit,
  updateDoc,
} from "firebase/firestore";
import { isMobile } from "mobile-device-detect";

const state = {
  show: {
    movie: {},
    shows: [],
  },
  shows: [],
  seats: [],
  selectedShow: {
    location: "",
    show: null,
  },
  fetchingData: false,
  fetchingSeats: false,
  transactionProgress: false,
  dataLoaded: false,
  seatSnapshot: null,
  platformName: isMobile ? "MOBILE" : "DESKTOP",
};

const getters = {
  seats: (state) => state.show.seats,
  standingAvailable: (state) => (state.show.standing ? true : false),
  mapSeat: () => (seat) => {
    return {
      id: seat.id,
      label: seat.label,
      type: seat.type,
      category: seat.category,
      price: seat.price,
    };
  },
  mappedReservation: (state, getters, rootState) => (reservation) => {
    reservation.price = sumByCol(reservation.seats, "price");
    reservation.paymentCurrency = rootState.show.show.paymentCurrency;
    const guests = [];
    const user = rootState.auth.currentUser
      ? rootState.auth.currentUser
      : rootState.auth.guest;
    for (let index = 0; index < reservation.seats.length - 1; index++) {
      guests.push(user?.name);
    }

    reservation.guests = guests;
    reservation.tickets = reservation.seats.length;
    // reservation.event.date = Timestamp.fromDate(
    //   new Date(state.show.start.toDate())
    // );

    let subTotal = Dinero({
      amount: toDecimal(reservation.price) * 100,
      currency: state.show.paymentCurrency,
    });

    let serviceCharge = state.show.serviceCharge * reservation.tickets;
    if (state.show.fees?.percentage) {
      serviceCharge = subTotal
        .add(
          Dinero({
            amount: toDecimal(state.show.fees?.stripeTransactionFee || 0),
            currency: state.show.paymentCurrency,
          })
        )
        .divide((100 - state.show.fees?.percentage || 0) / 100)
        .subtract(subTotal);

      serviceCharge = serviceCharge.getAmount() / 100;
    }

    reservation.serviceCharge = serviceCharge;

    // reservation.price += reservation.serviceCharge;

    // delete reservation.id;
    return reservation;
  },
  seatCategories: (state, getters, rootState) => {
    const categories = state.seats
      .filter((a) => a.category)
      .map((a) => {
        return {
          name: a.category,
          price: a.price,
          color: a.color,
        };
      });

    const uniqueCategories = orderBy(
      [...new Set(categories.map(JSON.stringify))].map(JSON.parse),
      ["price"],
      ["asc"]
    );

    return uniqueCategories.map((a) => {
      a.tickets = rootState.reservation.selectedSeats.filter(
        (s) => s.category === a.name
      ).length;
      a.total = a.tickets * a.price;
      return a;
    });
  },
  paymentTypes: (state) => {
    const paymentTypes = [];
    if (state.show.paymentTypes) {
      if (state.show.paymentTypes.sepa) paymentTypes.push("sepa_debit");
      if (state.show.paymentTypes.card) paymentTypes.push("card");
      if (state.show.paymentTypes.giropay) paymentTypes.push("giropay");
      if (state.show.paymentTypes.sofort) paymentTypes.push("sofort");
      if (state.show.paymentTypes.klarna) paymentTypes.push("klarna");
      if (state.show.paymentTypes.paypal) paymentTypes.push("paypal");
    }

    return paymentTypes;
  },
  firstShow: (state) => {
    return state.shows.length ? state.shows[0] : {};
  },
  locations: (state) => {
    const _locations = [];
    state.shows.map((a) => {
      if (_locations.findIndex((l) => l.name === a.cinema.name) === -1) {
        _locations.push(a.cinema);
      }
    });
    return orderBy(_locations, ["city"], ["asc"]);
  },

  showTimes: (state) => {
    if (!state.selectedShow.location) return [];
    const _dateTime = [];
    state.shows
      .filter((s) => s.cinema.name === state.selectedShow.location)
      .map((a) => {
        if (_dateTime.findIndex((l) => l.name === a.cinema.name) === -1) {
          _dateTime.push({
            ...a,
            ...{
              time: a.start,
            },
          });
        }
      });
    return orderBy(_dateTime, ["time"], ["asc"]);
  },
};

const actions = {
  async fetchBySlug({ commit, state, dispatch }, slug) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("movie.slug", "==", slug),
      where("start", ">=", Timestamp.now()),
      where("maintenance", "==", false)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then(async (snap) => {
          const v = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            showData.id = doc.id;
            v.push(showData);
          });

          await commit("setState", { key: "shows", value: v });
          dispatch("setDefaultShowSelection");
          state.fetchingData = false;
          state.dataLoaded = true;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async fetchShowByCity({ commit, state }, city) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("city", "==", city),
      where("start", ">=", Timestamp.now()),
      where("maintenance", "==", false)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then(async (snap) => {
          const v = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            const exist = v.findIndex((e) => e.title === showData.movie.title);
            if (exist === -1) {
              v.push({ ...showData, ...{ id: doc.id } });
            } else {
              v[exist].showsCount++;
            }
          });

          commit("setShows", v);
          state.fetchingData = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async findByCityAndMovie({ commit, state }, payload) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("city", "==", payload.city),
      where("movie.title", "==", payload.title),
      where("maintenance", "==", false)
    );

    return new Promise((resolve, reject) => {
      getDocs(q)
        .then(async (snap) => {
          const shows = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            const date = new Date(showData.start.toDate());
            showData.isPast = false;
            if (Date.now() > date.getTime()) {
              showData.isPast = true;
            }
            shows.push(showData);
          });

          const show = shows[0];
          commit("setShow", show);
          commit("setShows", shows);
          state.fetchingData = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async findShow({ state, commit }, id) {
    state.fetchingData = true;
    console.log("Fetching shows...");
    const q = query(
      collection(firebaseDB, "screenings"),
      where("maintenance", "==", false),
      where("slug", "==", id),
      limit(1)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then((snap) => {
          snap.forEach((doc) => {
            const show = doc.data();

            commit("updateShow", { ...{ id: doc.id }, ...show });
            commit("event/setEventFromShow", state.show, { root: true });
          });

          state.fetchingData = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });

      // onSnapshot(q, (querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      //     commit("updateShow", { ...{ id: doc.id }, ...doc.data() });
      //     commit("event/setEventFromShow", state.show, { root: true });

      //     state.fetchingData = false;
      //     console.log("Show updated...");
      //     resolve(true);
      //   });
      // });
    });
  },

  async fetchShowSeats({ state, commit }) {
    state.fetchingSeats = true;
    commit("setState", { key: "fetchingSeats", value: true });
    let q = null;
    if (process.env.VUE_APP_STAGE_LIMIT) {
      q = query(
        collection(firebaseDB, `screenings/${state.show.id}/seats`),
        limit(process.env.VUE_APP_STAGE_LIMIT)
      );
    } else {
      q = query(collection(firebaseDB, `screenings/${state.show.id}/seats`));
    }

    console.log("Fetching seats..");
    state.seatSnapshot = onSnapshot(q, (querySnapshot) => {
      const seats = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          seats.push(change.doc.data());
        }
        if (change.type === "modified") {
          commit("updateSeat", change.doc.data());
          console.log("Seat Modified...");
        }
      });
      if (!state.seats.length) {
        commit("setState", { key: "seats", value: seats });
        console.log("Seats fetched..");
      }
      commit("setState", { key: "fetchingSeats", value: false });
    });
  },

  async unsubscribeSeatSeatSnapshot({ state }) {
    if (state.seatSnapshot) {
      console.log("Seat listener stopped");
      state.seatSnapshot();
      state.seatSnapshot = null;
      state.seats = [];
    }
  },
  async setDefaultShowSelection({ getters, commit, state }) {
    if (getters.locations.length === 1) {
      await commit("setState", {
        key: "selectedShow",
        value: {
          location: getters.locations[0].name,
        },
      });
    }
    if (getters.showTimes.length === 1) {
      await commit("setState", {
        key: "selectedShow",
        value: {
          location: state.selectedShow.location,
          show: getters.showTimes[0],
        },
      });
    }
  },

  async resetShowSelection({ state }) {
    state.selectedShow = {
      show: "",
      location: "",
    };
  },

  toggleSeatSelection({ state }, seat) {
    return new Promise((resolve) => {
      const docRef = doc(
        firebaseDB,
        `screenings/${state.show.id}/seats`,
        seat.id
      );

      const data = {
        status: SeatStatus.PAID,
      };

      setDoc(docRef, data, { merge: true })
        .then(() => {
          console.log("Document has been updated successfully");
        })
        .catch((error) => {
          console.log(error);
        });

      state.selectedSeats.push(seat);
      resolve(true);
    });
  },

  async markSeatAs({ state, rootState, rootGetters }, payload) {
    const show = rootState.show.show;
    const user = rootState.auth.currentUser;

    const seatDocRef = doc(
      firebaseDB,
      `screenings/${state.show.id}/seats`,
      payload.seat_id
    );

    let reservation = null;
    let reservationDocRef = null;
    if (rootGetters["reservation/isExistReservation"]) {
      reservationDocRef = doc(
        firebaseDB,
        `screenings/${show.id}/reservations`,
        rootState.reservation.reservationID
      );
    }
    state.transactionProgress = true;
    return runTransaction(firebaseDB, async (transaction) => {
      const seatDoc = await transaction.get(seatDocRef);
      const seat = seatDoc.data();

      const promoterId = Object.keys(show.promoters)[0];
      const promoterDoc = await transaction.get(
        doc(firebaseDB, `users`, promoterId)
      );

      let seatStatus = seat.status;
      // ! Seat transaction
      if (payload.status === SeatStatus.PAID) {
        await transaction.update(seatDocRef, {
          status: SeatStatus.FREE,
        });
        seatStatus = SeatStatus.FREE;
      } else if (seat.status === SeatStatus.PAID) {
        state.transactionProgress = false;
        throw new SeatAlreadyOccupiedError();
      }

      if (payload.status !== SeatStatus.PAID) {
        await transaction.update(seatDocRef, {
          status: SeatStatus.PAID,
        });
        seatStatus = SeatStatus.PAID;
      }

      // ! Reservation transaction
      if (rootGetters["reservation/isExistReservation"]) {
        reservation = rootState.reservation.reservation;
        let seats = cloneCopy(reservation.seats);

        const existSeat = seats.find((a) => a.id === payload.seat_id);
        if (existSeat) {
          seats = seats.filter((a) => a.id !== existSeat.id);
        } else {
          seats.push(rootGetters["show/mapSeat"](seat));
        }

        const mappedReservation = rootGetters["show/mappedReservation"]({
          seats: seats,
        });

        await transaction.update(reservationDocRef, mappedReservation);
        reservation = { ...reservation, ...mappedReservation };
      } else {
        const reservationDoc = collection(
          firebaseDB,
          `screenings/${show.id}/reservations`
        );

        reservation = {
          state: ReservationStatus.NEW,
          created: Timestamp.now(),
          device: `WEB-${state.platformName}`,
          name: user.name || "",
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email,
          city: user.city || "",
          phone: user.phone || "",
          userId: user.uid || "",
          type: show.tickets ? SeatingType.STANDING : SeatingType.SEATING,
          promoterId: promoterId,
          promoterName: promoterDoc.data().name,
          event: {
            eventId: show.eventId,
            date: show.start,
            id: show.id,
            slug: show.slug,
            name: show.movie.title,
            location: {
              name: show.cinema.name,
              hall: show.cinema.hall,
              street: show.cinema.street,
              postal: show.cinema.postal,
              city: show.cinema.city,
            },
            posterUrlSquare: show.movie.posterUrlSquare,
          },
          seats: [],
        };
        reservationDocRef = doc(reservationDoc);
        reservation.seats.push(rootGetters["show/mapSeat"](seat));

        const mappedReservation = rootGetters["show/mappedReservation"]({
          seats: reservation.seats,
        });

        reservation = { ...reservation, ...mappedReservation };

        await transaction.set(reservationDocRef, reservation);

        saveStorage("RID", reservationDocRef.id);
        saveStorage("SID", show.id);
      }

      reservation.id = getStorage("RID");
      state.transactionProgress = false;
      return {
        reservation,
        seat: {
          ...seat,
          ...{ status: seatStatus, loading: false },
        },
      };
    });
  },

  async reserveTickets({ state, rootState, rootGetters }, payload) {
    const show = rootState.show.show;
    const user = rootState.auth.currentUser;

    let reservation = null;
    let reservationDocRef = null;
    if (rootGetters["reservation/isExistReservation"]) {
      reservationDocRef = doc(
        firebaseDB,
        `screenings/${show.id}/reservations`,
        rootState.reservation.reservationID
      );
    }

    const screeningDocRef = doc(firebaseDB, `screenings`, state.show.id);
    state.transactionProgress = true;
    return runTransaction(firebaseDB, async (transaction) => {
      const screeningDoc = await transaction.get(screeningDocRef);
      const screening = screeningDoc.data();

      const promoterId = Object.keys(show.promoters)[0];
      const promoterDoc = await transaction.get(
        doc(firebaseDB, `users`, promoterId)
      );

      let ticketDiff = payload.tickets;
      if (rootGetters["reservation/isExistReservation"]) {
        const currentTickets = rootState.reservation.reservation.tickets;
        ticketDiff = payload.tickets - currentTickets;
      }

      const canContinue =
        payload.tickets <= screening.tickets[payload.category].left;

      if (!canContinue) {
        throw new NotEnoughTicketsLeftError(
          screening.tickets[payload.category].left
        );
      }

      await transaction.update(screeningDocRef, {
        [`tickets.${payload.category}.left`]:
          screening.tickets[payload.category].left - ticketDiff,
      });

      const seats = [];
      for (let index = 0; index < payload.tickets; index++) {
        seats.push({
          id: index + 1,
          category: payload.category,
          label: `PASS${index + 1}`,
          price: screening.tickets[payload.category].price,
          type: SeatingType.STANDING,
        });
      }

      // ! Reservation transaction
      if (rootGetters["reservation/isExistReservation"]) {
        reservation = rootState.reservation.reservation;
        const mappedReservation = rootGetters["show/mappedReservation"]({
          seats: seats,
        });

        await transaction.update(reservationDocRef, mappedReservation);
        reservation = { ...reservation, ...mappedReservation };
      } else {
        const reservationDoc = collection(
          firebaseDB,
          `screenings/${show.id}/reservations`
        );

        reservation = {
          state: ReservationStatus.NEW,
          created: Timestamp.now(),
          device: `WEB-${state.platformName}`,
          name: user.name,
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email,
          phone: user.phone || "",
          city: user.city || "",
          userId: user.uid || "",
          type: SeatingType.STANDING,
          promoterId: promoterId,
          promoterName: promoterDoc.data().name,
          event: {
            eventId: show.eventId,
            date: show.start,
            id: show.id,
            slug: show.slug,
            name: show.movie.title,
            location: {
              name: show.cinema.name,
              hall: show.cinema.hall,
              street: show.cinema.street,
              postal: show.cinema.postal,
              city: show.cinema.city,
            },
            posterUrlSquare: show.movie.posterUrlSquare,
          },
          seats: seats,
        };
        reservationDocRef = doc(reservationDoc);

        const mappedReservation = rootGetters["show/mappedReservation"]({
          seats: seats,
        });

        reservation = { ...reservation, ...mappedReservation };

        await transaction.set(reservationDocRef, reservation);

        saveStorage("RID", reservationDocRef.id);
        saveStorage("SID", show.id);
      }

      reservation.id = getStorage("RID");
      state.transactionProgress = false;
      return {
        reservation,
        seat: {},
      };
    });
  },

  async updateSeatsByLabel({ commit }, payload) {
    console.log(commit);

    try {
      const seatsCollection = collection(
        firebaseDB,
        "/screenings/L3WiB0Qoxcv8QwkoR2zc/seats"
      ); // Assuming your collection is named 'seats'

      const promises = payload.labelsToUpdate.map(async (label) => {
        const q = query(seatsCollection, where("category", "==", "VIP"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
          console.log(label);
          await updateDoc(doc.ref, payload.updateData);
        });
      });

      await Promise.all(promises); // Wait for all updates to complete
      console.log("Seats updated successfully!");
    } catch (error) {
      console.error("Error updating seats:", error);
    }
  },

  assignState({ commit }, options) {
    return new Promise((resolve) => {
      commit("setState", options);
      resolve(true);
    });
  },

  addShow({ commit }, show) {
    commit("newShow", show);
  },

  attachShow({ commit }, show) {
    commit("updateShow", show);
  },

  attachSeat({ commit }, seat) {
    commit("updateSeat", seat);
  },

  assignShow({ commit }, show) {
    commit("setShow", show);
  },
};

const mutations = {
  setShows(state, shows) {
    state.shows = shows;
  },

  updateShow(state, show) {
    const index = state.shows.findIndex((a) => a.id === show.id);
    state.shows[index] = show;
    state.show = show;
  },

  updateSeat(state, seat) {
    const seatIndex = state.seats.findIndex((a) => a.id === seat.id);
    state.seats[seatIndex] = seat;
  },

  setShow(state, show) {
    state.show = show;
  },

  setState(state, options) {
    return new Promise((resolve) => {
      state[options.key] = options.value;
      resolve(true);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
